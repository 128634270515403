$font-path : '../fonts/';

@font-face {
  font-family: 'Cera Round Pro';
  src: url('#{$font-path}CeraRoundPro-Black.woff2') format('woff2'),
       url('#{$font-path}CeraRoundPro-Black.woff') format('woff');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Round Pro';
  src: url('#{$font-path}CeraRoundPro-Bold.woff2') format('woff2'),
       url('#{$font-path}CeraRoundPro-Bold.woff') format('woff');
  font-weight: bold;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Round Pro';
  src: url('#{$font-path}CeraRoundPro-Regular.woff2') format('woff2'),
       url('#{$font-path}CeraRoundPro-Regular.woff') format('woff');
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

$Cera-font   : 'Cera Round Pro';
$System-font : -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$base-font : $Cera-font;

.text-center {
  text-align: center !important;
}
