.modal-wrap {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: transparentize(white, .2);
}
.modal-wrap--open {
  display: flex;
}
.modal-window {
  display: none;
  position: relative;
  flex-shrink: 0;
  padding: 2rem;
  margin: auto;
  width: 85%;
  // overflow: hidden;
  // border-radius: 50%;
  // width: 30rem;
  // height: 30rem;
  background-color: color(black);
  @include media($lg) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 6rem;
    padding-right: 6rem;
    width: 90rem;
    height: 90rem;
    border-radius: 50%;
  }
}
.modal-window--open {
  display: block;
  @include media($lg) {
    display: flex;
  }
}
@each $name, $color in $colors {
  .modal-window--#{$name} {
    background-color: var(#{--color-#{$name}});
  }
}
.modal-window__content {
  flex-shrink: 0;
  margin: auto;
  color: white;
}
.modal-title {
  margin-bottom: 2rem;
  color: color(white);
  font-family: $base-font;
  font-size: 2rem;
  line-height: 1;
  font-weight: 900;
  text-align: center;
  @include media($lg) {
    margin-bottom: 5rem;
    font-size: 12rem;
  }
}
.modal-text {
  color: white;
  font-family: $base-font;
  font-weight: 700;
  text-align: center;
  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }
  @include media($lg) {
    font-size: 2.4rem;
  }
}
.modal-window__close-btn {
  @include button-reset;
  position: absolute;
  top: 0;
  right: 0;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  transform: translate(50%, -50%);
  background-color: color(black);
  &:hover {
    .modal-window__close-btn-line,
    .modal-window__close-btn-line::before {
      background-color: lighten(red, 10%);
    }
  }
  @include media($lg) {
    top: 10%;
    right: 10%;
    width: 8.5rem;
    height: 8.5rem;
    transform: translate(0,0);
  }
}
.modal-window__close-btn-line {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: .5rem;
  border-radius: .5rem;
  background-color: color(white);
  transform: translate(-50%, -50%) rotate(-45deg);
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: .5rem;
    background-color: color(white);
    transform: rotate(-90deg);
  }
}
.modal-list {
  display: inline-block;
  text-align: left;
}
.modal-list__li {
  &:not(:last-child) {
    margin-bottom: 1rem;
    @include media($lg) {
      margin-bottom: 3rem;
    }
  }
}
.modal-list__link {
  display: flex;
  align-items: center;
  color: color(white);
  font-family: $base-font;
  font-size: 1.4rem;
  font-weight: 700;
  transition: color 200ms ease-in-out;
  &:hover {
    color: color(black);
  }
  @include media($lg) {
    font-size: 2.8rem;
  }
}
.modal-list__link-icon {
  font-size: 2rem;
  margin-right: 1.5rem;
  @include media($lg) {
  margin-right: 4rem;
  font-size: 3.8rem;
  }
}
