.map-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.map-pin {
  position: absolute;
  display: inline-block;
  margin-top: -.5rem;
  border-radius: 50%;
  padding: .3rem;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    top: calc(100% - .1rem);
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: .4rem .4rem 0 .4rem;
    transform: translateX(-50%) rotate(360deg);
  }
  @include media($lg) {
    padding: .6rem;
  }
  @include media($hg) {
    padding: .8rem;
  }
}
.map-pin__content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: white;
  border-radius: 50%;
  overflow: hidden;
  transform: translateZ(0);
  transition: width 150ms ease-in-out,
              height 150ms ease-in-out;
  z-index: 10;
  @include media($lg) {
    width: 1.8rem;
    height: 1.8rem;
  }
  @include media($hg) {
    width: 2rem;
    height: 2rem;
  }
}
.map-pin__content-image {
  display: block;
  width: 100%;
  height: auto;
  opacity: 0;
  transform: scale(0) translateZ(0);
  transition: transform 150ms ease-in-out,
              opacity 150ms ease-in-out;
}
.map-pin__info {
  position: absolute;
  padding: .6rem 1rem .6rem 1rem;
  border-top-right-radius: 2.5rem;
  border-bottom-right-radius: 2.5rem;
  left: calc(100% - .5rem);
  top: 50%;
  opacity: 0;
  width: 0;
  height: 0;
  transform: translateY(-50%);
  transition: opacity 150ms ease-in-out;
  overflow: hidden;
  @include media($lg) {
    padding: .75rem 1.5rem .75rem 1.2rem;
  }
  @include media($hg) {
    padding: .9rem 2.5rem .9rem 1rem;
  }
}
.map-pin__info-text {
  color: color(white);
  white-space: nowrap;
  font-family: $base-font;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: 900;
  @include media($sm) {
    font-size: 1.8rem;
  }
  @include media($lg) {
    font-size: 2.2rem;
  }
  @include media($hg) {
    font-size: 3.5rem;
  }
}
@each $name, $color in $colors {
  .map-pin--#{$name} {
    background-color: var(#{--color-#{$name}});
    &::before {
      border-color: var(#{--color-#{$name}}) transparent transparent transparent;
    }
    .map-pin__info {
      background-color: var(#{--color-#{$name}});
    }
  }
}
.map-pin {
  &:hover {
    z-index: 50;
    &::before {
      display: none;
    }
    .map-pin__content-image {
      opacity: 1;
      transform: scale(1) translateZ(0);
    }
    .map-pin__info {
      width: auto;
      height: auto;
      opacity: 1;
      transition-delay: 100ms;
    }
    .map-pin__content {
      width: 7rem;
      height: 7rem;
      transform: transalteZ(0);
      @include media($lg) {
        width: 10rem;
        height: 10rem;
      }
      @include media($hg) {
        width: 14rem;
        height: 14rem;
      }
    }
  }
}
