.main-header {
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  @extend .main-container;
  // position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  pointer-events: none;
  @include media($sm) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @include media($lg) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  @include media($hg) {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
}
.main-header__logo {
  display: inline-block;
  width: 4rem;
  pointer-events: auto;
  @include media($sm) {
    width: 4.5rem;
  }
  @include media($lg) {
    width: 5.5rem;
  }
  @include media($hg) {
    width: 7.5rem;
  }
}
.main-header__logo-image {
  display: block;
  width: 100%;
}
.main-header__nav-link {
  pointer-events: auto;
  &:not(:last-child) {
    margin-right: .5rem;
    @include media($sm) {
      margin-right: 1rem;
    }
    @include media($lg) {
      margin-right: 2rem;
    }
    @include media($hg) {
      margin-right: 3.5rem;
    }
  }
}
