$small-screen   : 576px;
$medium-screen  : 768px;
$large-screen   : 992px;
$huge-screen    : 1200px;

$sm : $small-screen;
$md : $medium-screen;
$lg : $large-screen;
$hg : $huge-screen;

@mixin media($size) {
  @media screen and (min-width: $size) {
    @content
  }
};
