:root {
  --safe-area-inset-left : env(safe-area-inset-left);
  --safe-area-inset-right : env(safe-area-inset-right);
}
html,
body {
  height: 100%;
}
body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-family: $System-font;
  font-size: 1.4rem;
  color: black;
  background-color: white;
}
.page-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
}
.main-container {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  @include media($sm) {
    padding-left: 2.2rem;
    padding-right: 2.2rem;
  }
  @include media($lg) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @include media($hg) {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
}
@supports(padding: max(0px)) {
  .main-container {
    padding-left: max(1.5rem, var(--safe-area-inset-left));
    padding-right: max(1.5rem, var(--safe-area-inset-right));
  }
}
