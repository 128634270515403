@mixin button-reset {
  display: inline-block;
  padding: 0;
  border: .1rem solid transparent;
  font: inherit;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  &::-moz-focus-inner {
    border: none;
  }
}


@function max($numbers...) {
  @return m#{a}x(#{$numbers})
};

@function min($numbers...) {
  @return m#{i}n(#{$numbers})
};
