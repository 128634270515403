$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts/icomoon" !default;

$instagram: "\e900";
$telephone-call: "\e901";
$wedding-invitation: "\e902";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.instagram {
  &:before {
    content: $instagram;
  }
}
.telephone-call {
  &:before {
    content: $telephone-call;
  }
}
.wedding-invitation {
  &:before {
    content: $wedding-invitation;
  }
}

