.main-footer {
  // position: absolute;
  // bottom: 0;
  // left: 0;
  // right: 0;
  @extend .main-container;
  // position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
  z-index: 10;
  pointer-events: none;
  @include media($sm) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @include media($lg) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  @include media($hg) {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
}
.main-footer__nav-link {
  pointer-events: auto;
}
