// Colors.
$black  : #000;
$white  : #fff;
$yellow : #e6b32f;
$blue   : #84b4c3;
$purple : #8b679f;
$rose   : #d58097;

// Colors Map.
$colors: (
  black   : $black,
  white   : $white,
  yellow  : $yellow,
  blue    : $blue,
  purple  : $purple,
  rose    : $rose
);

// Colors CSS Variables.
:root {
  @each $name, $color in $colors {
    #{--color-#{$name}}: $color;
  }
}

@function color($color-name) {
  @return var(--color-#{$color-name});
}
