.main-btn {
  @include button-reset;
  padding: .3rem 2.5rem;
  border-width: .2rem;
  background-color: color(black);
  border-color: color(black);
  border-radius: 1.6rem;
  font-family: $base-font;
  color: color(white);
  font-size: 1.4rem;
  font-weight: 900;
  transition: color 250ms ease-in-out,
              background-color 250ms ease-in-out;
  @include media($sm) {
    font-size: 1.8rem;
  }
  @include media($lg) {
    font-size: 2.1rem;
  }
  &:hover {
    background-color: color(white);
    color: color(black);
  }
}
@each $name, $color in $colors {
  .main-btn--#{$name} {
    background-color: var(#{--color-#{$name}});
    border-color: var(#{--color-#{$name}});
    &:hover {
      color: var(#{--color-#{$name}});
    }
  }
}
